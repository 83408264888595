export { FileUpload };

function FileUpload(file, url, progressUpdate = Function.prototype) {
  console.log({ file, url, progressUpdate });
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener(
      "progress",
      (e) => {
        if (e.lengthComputable) {
          const percentage = Math.round((e.loaded * 100) / e.total);
          progressUpdate(percentage);
        }
      },
      false
    );

    xhr.upload.addEventListener(
      "load",
      function (e) {
        progressUpdate(100);
        resolve();
      },
      false
    );
    xhr.open("PUT", url);
    xhr.overrideMimeType("text/plain; charset=x-user-defined-binary");
    xhr.send(file);
  });
}
//prettier
//prettier
//prettier
//prettier
//prettier
