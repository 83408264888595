<script>
  export let type;
  export let value = "";
  export let name;
  export let label;
  export let options = [];
  export let empty;

  let activeElement;
  let thisElement;

  function setActive(e) {
    activeElement = (e && e.target) || null;
  }
  function handleChange() {
    typeof empty === "function" && empty();
  }
</script>

<style>
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    max-width: 100%;
    margin: 0;
  }
  input:focus {
    outline: none;
  }
  .input-field {
    margin-top: 35px;
    position: relative;
  }
  .input-field label {
    position: absolute;
  }
  .input-field select {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 15px 0;
    color: white;
    border-bottom: 1px solid white;
    border-radius: 0;
    transition: box-shadow ease 1s;
    outline: none;
  }
  .input-field option {
    color: initial;
  }
  .input-field label {
    transition: all 0.3s;
  }
  .active label {
    transform: scale(0.7) translateY(-100%);
    transform-origin: top left;
    color: var(--inactive-color);
  }
  .active select {
    border-bottom: 2px solid white;
  }
  .icon {
    position: absolute;
    right: 10px;
    bottom: 20px;
    pointer-events: none;
  }
  .icon svg {
    stroke: white;
  }
  .invalid {
    -webkit-appearance: none;
    box-shadow: 0 8px 6px -6px var(--alert-color);
  }
</style>

<div
  class="input-field"
  class:active={(value && value !== '') || activeElement}>
  <label for={name}>{label}</label>
  <div class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-chevron-down"><polyline
        points="6 9 12 15 18 9" /></svg>
  </div>
  <select
    type={label}
    id={name}
    class:invalid={empty}
    on:focus={setActive}
    on:blur={() => setActive(null)}
    on:change={handleChange}
    required
    bind:value
    bind:this={thisElement}>
    <option />
    {#each options as opt}
      <option value={opt}>{opt}</option>
    {/each}
  </select>
</div>
