import App from "./App.svelte";

const app = new App({
  target: document.querySelector(".wrapper"),
  props: {
    name: "world"
  }
});

export default app;
