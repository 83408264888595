<script>
  import { FileUpload } from "./upload.js";
  export let status;
  export let name;
  export let label;
  export let accept = "";
  export let empty;
  export let id;
  export let mediaId;
  let file;
  let uploadStatus = 0;

  async function handleUpload(e, type, name) {
    const [uploadFile] = e.target.files;
    if (!uploadFile.type.startsWith(`${type}/`)) {
      return;
    }
    file = uploadFile.name;
    const [all, extension] = uploadFile.name.match(/\.([^.]+)$/);
    const response = await fetch(
      `/api/upload/${id}/?fileExtension=${extension}`,
      {
        method: "PUT",
      }
    );
    const uploadData = await response.json();
    const uploadURL = uploadData.url;
    mediaId = uploadData.contentId;
    typeof empty === "function" && empty();
    uploadStatus = 0;
    status = FileUpload(
      uploadFile,
      uploadURL,
      (per) => Number.isInteger(per) && (uploadStatus = per)
    );
    await status;
    status = null;
  }
  function getIcon(accept) {
    if (accept.startsWith("image")) {
      return "image.svg";
    }
    if (accept.startsWith("video")) {
      return "video.svg";
    }
    return "";
  }

</script>

<style>
  .upload input {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
  .upload button {
    width: 100%;
    pointer-events: none;
    margin-top: -14px;
  }
  .upload-status {
    background-color: var(--secondary-bg-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-radius: 5px; */
    /* box-shadow: 2px 2px 5px #155B4D; */
    margin: 25px 0;
    height: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: all 1s;
    margin-top: 0;
  }
  .upload-status .name {
    width: 150px;
    width: 18ch;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    font-size: small;
    margin: 0 7px;
  }
  .upload-status .name {
    font-size: small;
  }
  .upload-status.show {
    height: 114px;
    padding: 18px;
    border: 1px solid white;
    margin-top: 25px;
  }
  .input-upload.invalid {
    box-shadow: 0 8px 6px -6px var(--alert-color);
  }

</style>

<div class="input-upload" class:invalid={empty}>
  <label class="upload">
    <input
      type="file"
      required
      {accept}
      on:change={(e) => handleUpload(e, accept.split('/')[0], name)} /><button>{label}</button>
  </label>
  <div class="upload-status" class:show={!!file}>
    <img src={getIcon(accept)} />
    {#if file}
      <p>
        <span class="name">{file}</span>
        <!-- <span class="size">({img.size})</span> -->
      </p>
      <div>{uploadStatus}%</div>
    {/if}
  </div>
</div>
