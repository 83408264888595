<script>
  export let value = "";
  export let empty;
  export let title;
  export let alternatives = [];

  $: console.log({ empty });

  function handleChange(e) {
    value = e.target.value;
    typeof empty === "function" && empty();
  }

</script>

<style>
  .input-radio {
    margin-bottom: 20px;
    margin-top: 35px;
  }
  sl-radio::part(base) {
    align-items: center;
  }
  sl-radio::part(control) {
    border-color: white;
    background-color: var(--main-bg-color);
  }
  sl-radio::part(label)::after {
    display: none;
  }
  .label {
    font-size: 1rem;
    font-family: var(--font);
    margin: 0.5rem;
  }
  .input-radio.invalid {
    box-shadow: 0 8px 6px -6px var(--alert-color);
  }

</style>

<div class="input-radio" class:invalid={empty}>
  {title}

  <sl-radio-group size="large" on:sl-change={handleChange}>
    {#each alternatives as alternative}
      <sl-radio value={alternative.value}>
        <p class="label">{alternative.label}</p>
      </sl-radio>
    {/each}
  </sl-radio-group>
</div>
