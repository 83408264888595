<script>
  export let type;
  export let value = false;
  export let name;
  export let label;
  export let empty;

  function handleChange(e) {
    value = e.target.checked;
    typeof empty === "function" && empty();
  }

</script>

<style>
  .input-checkbox {
    display: flex;
    align-items: baseline;
  }
  sl-checkbox::part(base) {
    align-items: center;
  }
  sl-checkbox::part(control) {
    border-color: white;
    background-color: var(--main-bg-color);
  }
  sl-checkbox::part(label)::after {
    display: none;
  }
  .label {
    font-size: 1rem;
    font-family: var(--font);
  }

  .input-checkbox.invalid {
    box-shadow: 0 8px 6px -6px var(--alert-color);
  }

</style>

<div class="input-checkbox" class:invalid={empty}>
  <sl-checkbox
    size="large"
    class:invalid={empty}
    on:click={handleChange}
    required>
    <p class:label>{label}</p>
  </sl-checkbox>
</div>
