<script>
  import Form from "./Form.svelte";
  let step = 0;
  let data = {};
  let isStarted = false;
  const overrideClose = new URLSearchParams(window.location.search).has(
    "lateapplication"
  );
  async function startSubmission() {
    if (!isStarted) {
      isStarted = true;
      const response = await fetch(`/api`, { method: "PUT" });
      const initialData = await response.json();

      data.id = initialData.submissionId;
    }
  }

</script>

<style>
  .header {
    position: relative;
    background-color: #ecede8;
    height: 70vw;
    max-height: 500px;
    overflow: hidden;
  }
  .header #hero_bg {
    object-position: 50% 8%;
  }
  .header #megatitle {
    width: 90%;
    position: absolute;
    bottom: 0vw;
    left: 5%;
    top: auto;
  }
  .header #megatitle img {
    margin-bottom: -2.2vw;
  }
  .header #megatitle h2 {
    font-size: 6vw;
    text-align: center;
    margin: 0;
    color: white;
  }
  .header svg {
    position: absolute;
    bottom: -1px;
    width: 240vw;
    height: 20vw;
    right: -1px;
    fill: var(--main-bg-color);
    filter: drop-shadow(3px -2px 3px rgba(0, 0, 0, 0.4));
  }
  .header h1 {
    position: absolute;
    display: block;
    height: 0;
    width: 0;
    overflow: hidden;
  }
  .header img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }
  @media (min-width: 1000px) {
    .header.full-bleed > * {
      margin: 0 auto;
    }
    .header.full-bleed > svg {
      margin: 0;
    }
    .header #megatitle {
      left: 0;
      max-width: 900px;
      left: calc((100% - 900px) / 2);
      position: absolute;
    }
    .header #megatitle img {
      margin-bottom: -29px;
    }
    .header #megatitle h2 {
      font-size: 64px;
    }
    .header svg {
      height: 200px;
    }
  }
  main {
    margin-top: 0px;
    padding: 16px;
    color: white;
    line-height: 1.4;
    max-width: 100%;
  }
  @media (min-width: 350px) {
    main {
      min-width: 350px;
    }
  }

  h1 {
    color: white;
    text-transform: uppercase;
    font-size: 2em;
  }
  h2 {
    margin-top: 2rem;
    margin-bottom: -0.3rem;
  }
  .intro h2 {
    font-size: 1.2rem;
  }
  .intro h2 + h2 {
    margin-top: 0.5rem;
  }
  #important {
    margin: 2rem 0;
  }
  button.secondary {
    border: 0;
    text-decoration: underline;
    font-size: small;
  }

</style>

<div class="header full-bleed">
  <img id="hero_bg" src="/hero_bg.jpg" aria-hidden="true" />
  <svg
    aria-hidden="true"
    class="diagonal"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"><polygon points="0,100 100,0 100,100" /></svg>
  <div id="megatitle">
    <img src="/hero.svg" aria-hidden="true" />
    <h1>Klassen</h1>
    {#if overrideClose}
      <h2>Sök till nästa säsong!</h2>
    {:else}
      <h2>&nbsp</h2>
    {/if}
  </div>
</div>
<main>
  {#if !overrideClose}
    <h2>TACK FÖR ALLA ANSÖKNINGAR TILL KLASSEN</h2>

    <p>
      Tack till alla som sökt, nu är anmälan stängd för i år och vårt casting
      team kommer gå igenom alla ansökningarna som kommit in. Har du frågor om
      castingen kan du vända dig till castingklassen@gmail.com
    </p>

    <p>MVH Klassen-teamet</p>
  {:else if step === 0}
    <section class="intro">
      <h2>Vi söker killar & tjejer i åldern 12 -14 år från hela Sverige.</h2>
      <h2>INSPELNING SOMMAREN 2024 I MALMÖ.</h2>
      <p>
        På denna sidan söker du till Klassen. För att söka så måste du ha
        tillstånd av dina föräldrar. Till din ansökan ska du spela in en
        presentationsfilm och en self-tape. Instruktioner står i nästa steg.
      </p>
      <p id="important">
        <strong>Sista dagen att söka är söndag 21 januari.</strong>
      </p>
      <p>Lycka till och vi ser fram emot att se din provfilmning.</p>
      <p>MVH Klassen-teamet</p>
      <div class="nav-buttons">
        <button
          class="plausible-event-name=step1"
          on:click={() => {
            step++;
            window.scrollTo(0, 0);
          }}>Sök här</button>
      </div>
    </section>
  {:else if step === 1}
    <section>
      <h2>Så här spelar du in din presentationsfilm</h2>
      <p>
        I presentationsfilmen så ska du berätta om dig själv och varför du vill
        vara med i Klassen. Berätta gärna om du har erfarenhet av skådespeleri
        från tidigare. Tänk på att filma där det är tyst och bra ljus.
        Presentationsfilmen får vara max 3 min.
      </p>
      <h2>Så här spelar du in din self-tape</h2>
      <p>
        I din self-tape ska du skådespela att du ringer upp din bästa kompis. Du
        har lovat att ni ska sova över men nu måste du ställa in för du vill
        hänga med några andra i klassen som inte vill att din bästa kompis
        följer med. Du är rädd för att göra din bästis ledsen. Tänk på att filma
        där det är tyst och bra ljus. Din self-tape får vara max 3min.
      </p>
      <h2>Castingen kommer bli en egen TV-serie - Ny i Klassen</h2>
      <p>
        Under årets casting kommer vi göra en dokumentärserie om att söka till
        Klassen. Om du söker till Klassen så godkänner du också att din ansökan
        kan komma att användas i den nya serien.
      </p>
      <div class="nav-buttons">
        <button
          class="secondary plausible-event-name=go-back"
          on:click={() => {
            step--;
            window.scrollTo(0, 0);
          }}>Tillbaka</button>
        <button
          class="plausible-event-name=step2"
          on:click={() => {
            step++;
            window.scrollTo(0, 0);
            startSubmission();
          }}>Nästa</button>
      </div>
    </section>
  {:else}
    <Form bind:step bind:data />
  {/if}
</main>
