<script>
  export let type;
  export let value = "";
  export let name;
  export let label;
  export let size = "";
  export let empty;

  let activeElement;
  let thisElement;

  function setActive(e) {
    activeElement = (e && e.target) || null;
  }
  function handleInput(e) {
    typeof empty === "function" && empty();
    value = e.target.value;
  }

</script>

<style>
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .input-field input[type="date"] {
    padding-top: 25px;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input {
    max-width: 100%;
    margin: 0;
  }
  input:focus {
    outline: none;
  }
  .input-field {
    margin-top: 35px;
    position: relative;
  }
  .input-field label {
    position: absolute;
  }
  input:required {
    box-shadow: none;
  }
  input:required:autofill {
    box-shadow: 0 0 0 50px var(--main-bg-color) inset; /* Change the color to your own background color */
    -webkit-text-fill-color: white;
  }

  input:required:autofill:focus {
    box-shadow: 0 0 0 50px var(--main-bg-color) inset; /* Change the color to your own background color */
    -webkit-text-fill-color: white;
  }
  .input-field input {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 15px 0;
    color: white;
    border-bottom: 1px solid white;
    border-radius: 0;
    transition: box-shadow ease 1s, border-bottom ease 1s;
  }
  .input-field input.invalid {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    box-shadow: 0 8px 6px -6px var(--alert-color);
  }
  .input-field label {
    transition: all 0.3s;
  }
  .active label {
    transform: scale(0.7) translateY(-100%);
    transform-origin: top left;
    color: var(--inactive-color);
  }
  .active input {
    border-bottom: 2px solid white;
  }
  input.nofill {
    width: auto;
  }

</style>

<div class="input-field" class:active={value || activeElement}>
  <label for={name}>{label}</label>
  <input
    {type}
    id={name}
    {size}
    class:nofill={size}
    class:invalid={empty}
    on:focus={setActive}
    on:blur={() => setActive(null)}
    on:input={handleInput}
    required
    autocomplete="chrome-off"
    bind:this={thisElement}
    {value} />
</div>
