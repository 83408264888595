<script>
  import { onMount } from "svelte";
  import SimpleInput from "./SimpleInput.svelte";
  import SelectInput from "./SelectInput.svelte";
  import CheckboxInput from "./CheckboxInput.svelte";
  import RadioInput from "./RadioInput.svelte";
  import UploadInput from "./UploadInput.svelte";
  import { FileUpload } from "./upload.js";
  export let step = 4;
  export let alerts = [];
  export let data = {
    extras: "true",
  };
  let activeElement = null;
  let files = {};
  let uploads = {};
  let imgUpload = 0;
  let videoName;
  let videoUpload = 0;
  let uploadSettings;
  let emptyFields = {};
  const fields = [
    {
      type: "text",
      name: "name",
      label: "För- och efternamn",
    },
    {
      type: "date",
      name: "born",
      label: "Födelsedatum",
    },
    {
      type: "number",
      size: 3,
      name: "height",
      label: "Längd (cm)",
    },
    {
      type: "text",
      name: "address",
      label: "Adress",
    },
    {
      type: "number",
      size: 5,
      name: "postal-code",
      label: "Postnummer",
    },
    {
      type: "text",
      name: "city",
      label: "Ort",
    },
  ];
  onMount(() => {
    window.__data = data;
    document.addEventListener("keyup", (e) => {
      if (e.which == 13) {
        e.preventDefault();
        const focusables = Array.from(
          document.querySelectorAll(
            "section:not([hidden]) input, section:not([hidden]) button, section:not([hidden]) select"
          )
        );
        const next = focusables.findIndex((elem) => elem === e.target) + 1;
        if (next === focusables.length) {
          return;
        }
        focusables[next].focus();
      }
    });
  });
  function getAllFieldNames(fields) {
    const fieldNames = [...fields.map((f) => f.name)];
    fieldNames.push("parentName");
    fieldNames.push("parentEmail");
    fieldNames.push("parentPhone");
    fieldNames.push("parentPermission");
    return fieldNames;
  }
  function checkFilled(fields) {
    const fieldNames = getAllFieldNames(fields);
    return fieldNames.every((fieldName) => !!data[fieldName]);
  }
  function handleEmptyFields(fields) {
    const alertId = `${Math.round(Math.random() * 100000)}`;
    const fieldNames = getAllFieldNames(fields);
    emptyFields = fieldNames
      .map((fieldName) => !data[fieldName] && fieldName)
      .filter(Boolean)
      .reduce(
        (empty, field) => ({
          ...empty,
          [field]: () => {
            delete emptyFields[field];
            alerts = alerts.filter((item) => item.id !== alertId);
            emptyFields = emptyFields;
          },
        }),
        {}
      );
    alerts = [
      ...alerts,
      {
        title: "Tomma fält",
        body: "Du måste fylla i alla fält innan du kan gå vidare",
        id: alertId,
      },
    ];
  }

  function handleinProgressUploads() {
    const hasUploads = Object.values(uploads).filter(Boolean).length > 0;
    if (hasUploads) {
      alerts = [
        ...alerts,
        {
          title: "uppladdning pågår",
          body: "Det pågår en eller flera uppladdningar, du kan inte skicka in dina ansökan före dem är färdiga",
          id: `${Math.round(Math.random() * 100000)}`,
        },
      ];
    }
  }
  async function submit() {
    data.status = "submitted";
    const response = fetch(`/api/${data.id}`, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  function closeAlert(id) {
    alerts = alerts.filter((item) => item.id !== id);
  }

  function setActive(e) {
    activeElement = e && e.target;
  }

</script>

<style>
  .alert-zone {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 6px;
    z-index: 1;
  }
  .alert {
    background-color: var(--alert-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .alert > div {
    padding: 1em 2em;
  }
  button {
    cursor: pointer;
  }
  button.secondary {
    border: 0;
    text-decoration: underline;
    font-size: small;
  }
  .button-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
  .upload input {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
  .upload button {
    width: 100%;
    pointer-events: none;
    margin-top: -14px;
  }
  section > * {
    margin-top: 25px;
  }
  .input-group {
    padding: 16px 0;
  }
  #data-processing {
    font-style: italic;
  }

</style>

{#if alerts.length > 0}
  <div class="alert-zone">
    {#each alerts as alert}
      <div class="alert">
        <div>
          <h3>{alert.title}</h3>
          <p>{alert.body}</p>
        </div>
        <button
          class="secondary"
          on:click={() => {
            closeAlert(alert.id);
          }}>
          <img src="/x-circle.svg" alt="stäng" />
        </button>
      </div>
    {/each}
  </div>
{/if}
<section hidden={step !== 2}>
  <h3>Om dig</h3>
  {#each fields as field}
    {#if field.type == 'select'}
      <SelectInput
        name={field.name}
        bind:value={data[field.name]}
        options={field.options}
        empty={emptyFields[field.name]}
        label={field.label} />
    {:else}
      <SimpleInput
        name={field.name}
        bind:value={data[field.name]}
        options={field.options}
        empty={emptyFields[field.name]}
        size={field.size}
        type={field.type}
        label={field.label} />
    {/if}
  {/each}
  <SimpleInput
    name="some"
    bind:value={data.someAccounts}
    empty={emptyFields.someAccounts}
    type="text"
    label="Instagram / TikTok (frivillig):" />
  <div class="input-group">
    <SimpleInput
      name="parentname"
      bind:value={data.parentName}
      empty={emptyFields.parentName}
      type="text"
      label="Förälder/Målsmans namn" />
    <SimpleInput
      name="parentemail"
      bind:value={data.parentEmail}
      empty={emptyFields.parentEmail}
      type="text"
      label="Förälder/Målsmans E-post" />
    <SimpleInput
      name="tel"
      bind:value={data.parentPhone}
      empty={emptyFields.parentPhone}
      type="text"
      label="Förälder/Målsmans Telefonnummer" />
  </div>
  <div class="input-group">
    <CheckboxInput
      bind:value={data.parentPermission}
      empty={emptyFields.parentPermission}
      name="parentPermission"
      label="Jag har min förälders tillåtelse att söka till Klassen 8 och godkänner att min ansökan kan användas i serien ”Ny i Klassen”" />
  </div>
  <div class="button-wrapper">
    <button
      class="secondary plausible-event-name=go-back"
      on:click={() => {
        step--;
        window.scrollTo(0, 0);
      }}>Tillbaka</button>
    <button
      class="plausible-event-name=step3"
      on:click={() => {
        const isFilled = checkFilled(fields);
        if (isFilled) {
          step++;
        } else {
          handleEmptyFields(fields);
        }
        window.scrollTo(0, 0);
      }}>Nästa</button>
    <div />
  </div>
</section>
<section hidden={step !== 3}>
  <h3>Bild</h3>
  <p>Ta en bild på dig själv med bra ljus där hela ansiktet syns.</p>
  <UploadInput
    name="image"
    accept="image/*"
    bind:status={uploads.image}
    bind:mediaId={data.applicantImage}
    empty={emptyFields.imageName}
    id={data.id}
    label="Ladda upp din bild" />
  <h3>Så här spelar du in din presentationsfilm</h3>
  <p>
    I presentationsfilmen så ska du berätta om dig själv och varför du vill vara
    med i Klassen. Berätta gärna om du har erfarenhet av skådespeleri från
    tidigare. Tänk på att filma där det är tyst och bra ljus.
    Presentationsfilmen får vara max 3 min.
  </p>
  <UploadInput
    name="video_pres"
    accept="video/*"
    bind:status={uploads.videoPres}
    bind:mediaId={data.videoPresentation}
    empty={emptyFields.videoPres}
    id={data.id}
    label="Ladda upp din prentationsfilm" />
  <h3>Så här spelar du in din self-tape</h3>
  <p>
    I din self-tape ska du skådespela att du ringer upp din bästa kompis. Du har
    lovat att ni ska sova över men nu måste du ställa in för du vill hänga med
    några andra i klassen som inte vill att din bästa kompis följer med. Du är
    rädd för att göra din bästis ledsen. Tänk på att filma där det är tyst och
    bra ljus. Din self-tape får vara max 3min.
  </p>
  <UploadInput
    name="video_impro"
    accept="video/*"
    bind:status={uploads.videoSelfTape}
    bind:mediaId={data.selfTape}
    empty={emptyFields.videoSelfTape}
    id={data.id}
    label="Ladda upp din self-tape" />
  <RadioInput
    name="extras"
    title="Kan du även tänka dig att vara statist?"
    bind:value={data.extras}
    empty={emptyFields.extras}
    alternatives={[{ label: 'Ja', value: 'true' }, { label: 'Nej', value: 'false' }]} />
  <div>
    <CheckboxInput
      bind:value={data.gdpr}
      name="gdpr"
      empty={emptyFields.gdpr}
      label="Jag godkänner att BBC Studios tillfälligt får spara mina personuppgifter i ansökan för castingen till klassen 8. (Efter castingen kommer ansökan att raderas) 
" />
    <p>
      <small id="data-processing">
        Vi behöver under castingen spara och behandla personuppgifter om dig, så
        som fullständigt namn, födelsedatum och kontaktuppgifter med syfte att
        rollbesätta en ny serie för SVT. Du har rätt att kontakta oss om du vill
        ha ut information om de uppgifter vi har om dig, för att begära
        rättelse, överföring eller för att begära att vi begränsar behandlingen,
        för att göra invändningar eller begära radering av dina uppgifter. Detta
        gör du enklast genom att kontakta oss. Du når oss på
        castingklassen@gmail.com Om du har klagomål på vår behandling av dina
        personuppgifter har du rätt att ange klagomål till tillsynsmyndigheten
        Datainspektionen.
      </small>
    </p>
  </div>
  <div class="button-wrapper">
    <button
      class="secondary plausible-event-name=go-back"
      on:click={() => {
        step--;
        window.scrollTo(0, 0);
      }}>Tillbaka</button>
    <button
      class="plausible-event-name=submit"
      on:click={(e) => {
        const fields = ['extras', 'gdpr'].map((name) => ({ name }));
        e.preventDefault();
        e.stopPropagation();
        const isFilled = checkFilled(fields);
        const noUploads = Object.values(uploads).filter((val) => val === null).length === 3;
        if (isFilled && noUploads) {
          submit();
          step++;
        } else {
          handleEmptyFields(fields);
          handleinProgressUploads();
        }
        window.scrollTo(0, 0);
        return false;
      }}>Skicka in</button>
    <div />
  </div>
</section>
<section hidden={step !== 4}>
  <div class="input-group">
    <h2>TACK!</h2>
    <h3>NU ÄR DIN ANSÖKAN KLAR!</h3>
  </div>

  <h3>Bekräftelsemail</h3>
  <p>
    När vi mottagit din ansökan kommer du få mail om att vi mottagit din
    ansökan. Vill du ändra eller radera din ansökan kan du maila oss på:
    castingklassen@gmail.com
  </p>

  <h3>Vad händer sen?</h3>
  <p>
    Vi kommer att titta på ALLA ansökningar som kommer in. Vi kommer höra av oss
    till de som går vidare i castingen innan 1 april 2024. Om du inte har hört
    något från oss innan dess så har du tyvärr inte gått vidare.
  </p>

  <p>Vi ser fram emot att se din provfilmning.</p>
  <p>MVH Klassen-teamet</p>
</section>
